<template>
    <router-view /> 
</template>

<script>

export default {
    data(){
        return {
            imagen: null,
            img_fondo: null,
            slimOptions: {
                ratio: '3:1',
                label: '',
                size: { width: 250, height: 250 },
                minSize: { width: 50, height: 50 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
        }
    },
    watch: {
        imagen(val){
            if (val){
                this.img_fondo = this.$refs.cropLogo.instanciaCrop.dataBase64.output.image;
                console.log(this.img_fondo);
                // this.img_fondo = val.image
            }
            console.log(this.img_fondo);
        }
    },
}
</script>

<style lang="scss" scoped>
.br-t-12{
    border-radius: 12px 12px 0 0;
}
.tab-menu{
    text-decoration: none;
    border-radius: 12px 12px 0 0;
    color: var(--color-5d);
    .icon-circle-empty{visibility: hidden;}
    &.router-link-active{
        background: #FAF8FF;
        color: var(--color-general);
        .icon-circle-empty{visibility: visible;}
    }
}
.img-buscar{
    width: 90%;
    position: relative;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
}
.cover-cropper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FAF8FF;
  height: 100%;
  width: 100%;
  position: absolute;
  color: #DBDBDB;
}
.img-fla {
    width: 80%;
    position: relative;
    border-radius: 5px;
    left: 50%;
    bottom: 23px;
    transform: translate(-50%, 0);
}
</style>

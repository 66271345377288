<template>
   <modal ref="modal" titulo="Lista de respuestas" >
            <div class="container">
                <div class="row my-4">
                    <div class="col-lg-12 text-center">
                        <p class="f-12" v-if="additionalData.tipo==3">Estos son los valores de la pregunta "{{ additionalData.pregunta }}".</p>
                        <p class="f-12" v-else-if="additionalData.tipo==2">Estos son los tipos de la pregunta "{{ additionalData.pregunta }}".</p>
                        <p class="f-12" v-else>Este es el contenido de la pregunta "{{ additionalData.pregunta }}".</p>
                        <p class="f-12">Puedes modificar, borrar o añadir valores al campo seleccionado y ordenarlos.</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-lg-between bg-title-pregunta px-3 py-1 br-2">
                                        <p class="text-general f-12 f-600 my-auto">{{additionalData.pregunta}}</p>
                                    </div>
                                    <div class="d-middle justify-content-lg-between bg-title-pregunta my-1 px-3 py-1 br-2">
                                        <div class="d-flex w-90 h-25">
                                            <p class="text-general f-10 f-600 my-auto mr-3 h-25">Agregar</p>
                                            <el-input v-model="additionalDataType.valor" placeholder="Descripción" size="small" class="h-25 w-100 p-0 f-9" />
                                        </div>
                                        <i class="icon icon-plus-box icon-general-hover f-22 cr-pointer" @click="addValueList" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <draggable tag="div" :list="datos_adicionales_tipos" class="container mx-0 py-3" @change="dragMouseDown($event)" handle=".handle">
                                        <div v-for="datos_tipo in datos_adicionales_tipos" :key="datos_tipo.id" class="row border-bottom align-items-center">
                                            <div class="col">
                                                <div class="d-flex ml-3">
                                                    <i class="icon-menu handle cr-pointer mr-2" />
                                                    <p class="f-12 my-auto">{{ datos_tipo.valor }}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-1">
                                                <div class="d-flex justify-content-lg-end">
                                                    <i class="icon-trash-can-outline icon-general-hover f-18 cr-pointer" @click="eliminarTipoDatoAdicional({id: datos_tipo.id})"/>
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { AdditionalDataTypeVOFactory } from '~/domain/value_objects/configurar/almacen/datos_adicionales/factories/AdditionalDataTypeVOFactory'

export default {
    data(){
        return {
            editAdditionalData: '',
            additionalDataType: AdditionalDataTypeVOFactory.instantiateEmpty(),
            createAnswerVisible: false,
            additionalData: {},
        }
    },
    computed: {
        ...mapGetters({
            datos_adicionales_tipos: 'configurar/almacen/datos_adicionales/datos_adicionales_tipos',
            datos_adicionales_tipos_posicion: 'configurar/almacen/datos_adicionales/datos_adicionales_tipos_posicion',
        })
    },
    methods:{
        ...mapActions({
            crearTipoDatoAdicional: 'configurar/almacen/datos_adicionales/Action_create_datos_adicionales_tipos',
            Action_get_datos_adicionales_tipos_posicion: 'configurar/almacen/datos_adicionales/Action_get_datos_adicionales_tipos_posicion',
            obtenerTipoDatoAdicional: 'configurar/almacen/datos_adicionales/Action_get_datos_adicionales_tipos_unico',
            eliminarTipoDatoAdicional: 'configurar/almacen/datos_adicionales/Action_delete_datos_adicionales_tipos',
            editarTipoDatoAdicional: 'configurar/almacen/datos_adicionales/Action_edit_datos_adicionales_tipos',
        }),

        async toggle(additionalData){
            this.additionalData = additionalData
            this.additionalDataType.id_configuracion_datos_adicionales = additionalData.id

            await this.obtenerTipoDatoAdicional({id: additionalData.id})
            this.additionalDataType.valor = ""
            this.$refs.modal.toggle()
        },
        async addValueList(){
            if (this.additionalDataType.valor) {
                await this.crearTipoDatoAdicional(this.additionalDataType)
                this.additionalDataType.valor = ""
            }else{
                this.$notify({title: 'Agrega primero un valor', type:'info'})
            }
        },
        async dragMouseDown(e){
            console.log(e)
            let payload = {
                "id_configuracion_datos_adicionales_tipos": e.moved.element.id,
                "posicion": e.moved.newIndex + 1,
            }
            await this.Action_get_datos_adicionales_tipos_posicion(payload)
            await this.obtenerTipoDatoAdicional()
        },
    }
}
</script>

<style lang="scss" scoped>
.icon-agregar{
    background: var(--color-5d);
    color: white;
        &:hover{
            background: var(--color-general);
            color: white;
    }
}
.bg-title-pregunta{
    background: #F1F2F4;
}
</style>
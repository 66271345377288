<template>
    <modal ref="modal" titulo="Eliminar dato adicional" :btn-eliminar="true" @eliminar="$emit('eliminar'); toggle()">
        <div class="container">
            <div class="row my-4">
                <div class="col-lg-12 text-center">
                    <p class="f-12">¿Estás seguro que quiere eliminar este dato adicional?</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    methods:{
        toggle(){
            this.$refs.modal.toggle()
        }
    }
}
</script>

<style>

</style>
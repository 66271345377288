<template>
  <div class="tipos-de-material container-fluid">
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Lista de tipos de materiales" class="icon-option">
                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="createMaterialType" >
                        <div class="row border-bottom d-middle-center">
                            <p class="f-16 f-600 text-general mb-2">Crear tipo de material</p>   
                        </div>
                        <div class="row mx-0 d-middle-center py-4">
                            <div class="col-10 text-left">
                                <p class="input-label-top">Tipo de material</p>
                                <ValidationObserver ref="validator">
                                    <ValidationProvider name="tipo de material" rules="required" v-slot="{ errors }">
                                        <el-input v-model="createTypeName" size="small" placeholder="Tipo de material" />
                                        <VeeError :text="errors[0]"/>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </div>
                        </div>
                        <div class="text-right row border-top pt-2 justify-content-end pr-3">
                            <button class="btn btn-cerrar f-12" @click="createMaterialType = !createMaterialType">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="createMaterial">Guardar</button>
                        </div>
                        <el-tooltip slot="reference" content="Crear tipo de material" effect="light" visible-arrow>
                            <i class="icon-plus-circle  f-30 cr-pointer" @click="clean"/>
                        </el-tooltip>
                    </el-popover>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <draggable tag="div" :list="materialTypes" class="" handle=".handle" @change="updatePositionMaterial($event)">
                    <div v-for="(type, index) in materialTypes" :key="type.id" class="col-12 border-nth br-4 py-1 text-muted2 pl-2">
                        <div class="row d-flex my-auto">
                            <div class="col-3 d-flex justify-content-between pl-2" style="min-width: 200px;">
                                <div class="d-middle">
                                    <i class="icon-menu handle pl-3 pr-2" />
                                    <p class="f-12">{{ type.tipo_material }}</p>
                                </div>
                                <div class="wh-30">
                                    <el-tooltip content="Materiales con este tipo" effect="light" placement="right" visible-arrow>
                                        <p class="btn-general br-5 w-100 h-100 f-12 d-middle-center">{{ type.numero_materiales }}</p>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="col-auto ml-auto">
                                <div class="d-flex icon-option">
                                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="type.edit" >
                                            <div class="row border-bottom">
                                                <div class="col-lg-12 text-center">
                                                    <p class="f-16 f-600 text-general mb-2">Editar tipo de material</p>   
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-lg-12 text-left py-3">
                                                    <p class="input-label-top">Tipo de material</p>
                                                    <el-input v-model="editTypeName" size="small" :placeholder="type.tipo_material" />
                                                </div>
                                            </div>
                                            <div class="row border-top mt-3">
                                                <div class="col-lg-12">
                                                    <div class="d-flex justify-content-lg-end mt-3">
                                                        <button class="btn btn-cerrar cr-pointer f-12" @click="type.edit = !type.edit; editTypeName = ''">Cerrar</button>
                                                        <button class="btn btn-crear ml-2 text-white cr-pointer f-12" @click="editMaterialType(type, index)">Guardar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        <el-tooltip slot="reference" content="Editar" effect="light" visible-arrow>
                                            <i class="icon-pencil f-22 mr-1 cr-pointer"  @click="MostrarTipoMaterial(type)"/>
                                        </el-tooltip>
                                    </el-popover>
                                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline f-22 cr-pointer" @click="deleteMaterialType(type)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <modal-eliminar ref="modalDeleteMaterial" title="Eliminar tipo de material" mensaje="¿Estás seguro que quiere eliminar este tipo de material?" @delete="deleteMaterial" />
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {Notification} from 'element-ui'
export default {
    data(){
        return {
            editTypeName: '',
            createTypeName: '',
            createMaterialType: false,
            materialToDelete: {},
        }
    },
    computed: {
        ...mapGetters({
            materialTypes: 'configurar/almacen/tipos_material/materialTypes',
        }),
    },
    created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.tipo_material',
            
        ]);
    },
    methods: {
        ...mapMutations({
            set_material_types: 'configurar/almacen/tipos_material/set_material_types'
        }),
        ...mapActions({
           Action_list_material_types: 'configurar/almacen/tipos_material/Action_list_material_types' ,
           Action_create_material_type: 'configurar/almacen/tipos_material/Action_create_material_type',
           Action_edit_material_type: 'configurar/almacen/tipos_material/Action_edit_material_type',
           Action_delete_material_type: 'configurar/almacen/tipos_material/Action_delete_material_type',
           Action_update_position_materal_type: 'configurar/almacen/tipos_material/Action_update_position_materal_type'
        }),
        clean(){
            this.$refs.validator.reset()
        },
        deleteMaterialType(type){
            this.$refs.modalDeleteMaterial.toggle()
            this.materialToDelete = type
        },
        MostrarTipoMaterial(type){
            this.editTypeName = type.tipo_material;
        },
        async createMaterial(){
            const valid = await this.$refs.validator.validate();
            if(!valid) return;
            await this.Action_create_material_type({payload: { tipo_material: this.createTypeName} })
            this.createMaterialType = !this.createMaterialType
            this.createTypeName =  ''
        },
        async editMaterialType(type, index){
            const payload = {
                ...type,
                tipo_material: this.editTypeName
            }
            await this.Action_edit_material_type(payload)
            const customMaterialList = [...this.materialTypes]
            customMaterialList[index].edit = customMaterialList[index].edit
            this.set_material_types(customMaterialList)
            this.editTypeName = ''
        },
        async deleteMaterial(){
            this.Action_delete_material_type(this.materialToDelete)                
            this.Action_list_material_types()
        },
        async updatePositionMaterial(mat){
            const payload = {
              "id_configuracion_tipos_materiales": mat.moved.element.id,
              "posicion": mat.moved.newIndex
            }
            await this.Action_update_position_materal_type(payload)
            this.Action_list_material_types()
        },
    },
    async mounted(){
        await this.Action_list_material_types()
    },
}
</script>

<style lang="scss" scoped>
.border-nth{
    border-top: 1px solid #dee2e6 !important;
    &:nth-last-child(1){
        border-bottom: 1px solid #dee2e6 !important;
    }
}
</style>
<template>
    <section class="banners">
        <p class="f-30">Sin contenido</p>

    </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>

<template>
    <div class="valor-hora-hombre container px-5 mt-5">
        <ValidationObserver ref="validator">
            <div class="d-middle-center">
                <div class="border-general d-flex br-5 mr-5 pr-3">
                    <div class="bg-general br-5 py-2 d-middle-center" style="width: 200px;">
                        <p class="text-white f-15 f-600 py-1 text-center">Valor de hora/hombre</p>
                    </div>
                    <div class="d-middle-center gap-3 ml-3">
                        <template v-if="horas_hombre || edit">
                            <ValidationProvider name="horas hombre" rules="required|numeric" v-slot="{ errors }">
                                <el-input-number v-if="edit" v-model="MHValue" placeholder="valor horas hombre" size="small" :controls="false" />
                                <p v-else class="text-muted f-15 f-600">{{ MHValue | currency(`${getSigla} $`, 2, { thousandsSeparator: '.' })}}</p>
                                <template v-if="errors[0]">
                                    <el-tooltip placement="bottom" effect="light" :show-arrow="false">
                                        <template #content>
                                            <p class="text-danger">{{ errors[0] }}</p>
                                        </template>
                                        <i class="text-danger icon-alert f-16" />
                                    </el-tooltip>
                                </template>
                            </ValidationProvider>
                            <ValidationProvider name="Moneda" rules="required" v-slot="{ errors }">
                                <el-select v-if="edit" v-model="currency" size="small" filterable placeholder="Moneda" style="width: 100px">
                                    <el-option v-for="data in select_monedas" :label="data.sigla" :value="data.id">
                                        <p>{{ data.sigla }} ({{ data.nombre }})</p>
                                    </el-option>
                                </el-select>
                                <template v-if="errors[0]">
                                    <el-tooltip placement="bottom" effect="light" :show-arrow="false">
                                        <template #content>
                                            <p class="text-danger">{{ errors[0] }}</p>
                                        </template>
                                        <i class="text-danger icon-alert f-16" />
                                    </el-tooltip>
                                </template>
                            </ValidationProvider>
                        </template>
                        <p class="f-15 f-600" v-else>Sin valores asignados</p>
                    </div>
                </div>
                <div @click="edit = true" class="edit-valor d-middle-center wh-35 br-5 cr-pointer">
                    <el-tooltip content="Editar" effect="light" visible-arrow>
                        <i class="icon icon-pencil text-white f-22" />
                    </el-tooltip>
                </div>
            </div>
            <div v-if="edit" class="d-middle-center ml-5 mt-4">
                <button @click="action()" class="btn btn-general f-13 py-1 px-3" >Aceptar</button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    data(){
        return {
            MHValue: '',
            currency: null,
            edit: false
        }
    },
    computed:{
        ...mapGetters({
            select_monedas: 'selects/selects/select_monedas',
            horas_hombre: 'configurar/almacen/horas_hombre/horas_hombre'
        }),
        getSigla(){
            return this.select_monedas.find(e => e.id === this.currency)?.sigla ?? ''
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.valor_hora_hombre',
        ]);
        await this.Action_get_select_monedas()
        await this.list()
    },
    methods: {
        ...mapActions({
            obtenerHorasHombres: 'configurar/almacen/horas_hombre/Action_get_horas_hombre',
            crearHorasHombres: 'configurar/almacen/horas_hombre/Action_create_horas_hombre',
            editarHorasHombres: 'configurar/almacen/horas_hombre/Action_edit_horas_hombre',
            Action_get_select_monedas: 'selects/selects/Action_get_select_monedas'
        }),
        async list(){
            await this.obtenerHorasHombres()
            this.currency = this.horas_hombre?.id_moneda ?? null
            this.MHValue = parseInt(this.horas_hombre?.valor_hora_hombre ?? null)
        },
        action(){
            if (this.horas_hombre) this.editHorasHombres()
            else this.createHorasHombres()
        },
        async editHorasHombres(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            this.edit = false
            const payload = {
                id: this.horas_hombre.id,
                payload: { valor_hora_hombre: this.MHValue, id_moneda: this.currency }
            }
            await this.editarHorasHombres(payload)
        },
        async createHorasHombres(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            this.edit = false
            const payload = { valor_hora_hombre: this.MHValue, id_moneda: this.currency }
            await this.crearHorasHombres(payload)
            await this.list()

        }
    },
}
</script>

<style lang="scss" scoped>
.edit-valor{
    background: var(--color-5d);
    &:hover{
        background: var(--color-general);
    }
}
</style>
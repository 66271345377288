<template>
	<section class="almacen container-fluid">
		<navbar-admin titulo="Configuración de Almacen">
		<el-tooltip content="Ir a Almacen" placement="left" effect="light" visible-arrow>
			<div class="wh-42 bg-5d br-2 d-middle-center cr-pointer" @click="goToAlmacen">
			<i class="icon-factory text-white f-25" />
			</div>
		</el-tooltip>
		</navbar-admin>
		<div class="row">
			<tabs :tabs="tabsConfAlmacen" />
			<router-view />
		</div>
	</section>
</template>

<script>
export default {
    data() {
      return {
        titulo: '',
        tabsConfAlmacen: [
                {
                    titulo:'Tipos de material',
                    ruta:"configurar.almacen.tipo_material",
                },
                {
                    titulo:'Unidades de medida',
                    ruta:"configurar.almacen.unidad",
                },
                {
                    titulo:'Datos adicionales',
                    ruta:"configurar.almacen.datos_adicionales",
                },
                {
                    titulo:'Valor de hora/hombre',
                    ruta:"configurar.almacen.valor_hora_hombre",
                },
                {
                    titulo:'Incrementos porcentuales',
                    ruta:"configurar.almacen.incrementos_porcentuales",
                },
                {
                    titulo:'Estados de pedido internos',
                    ruta:"configurar.almacen.estados.pedidos.internos",
                },
            ],
      }
    },
    methods:{
		goToAlmacen(){
			this.$router.push({ name: 'almacen.main' })
		}
    },
    created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
        ]);
    },
}
</script>

<style>

</style>
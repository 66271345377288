<template>
  <div class="unidad-medida container-fluid">
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Lista de unidades de medida" class="icon-option">
                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="createUnitVisible" >
                        <div class="row border-bottom d-middle-center">
                            <p class="f-16 f-600 text-general mb-3">Crear unidad de medida</p>   
                        </div>
                        <ValidationObserver ref="validatorCreate">
                            <div class="row mx-0 d-middle-center py-4">
                                <div class="col-10 text-left">
                                    <ValidationProvider v-slot="{ errors }" name="unidad de medida" rules="required">
                                        <p class="input-label-top">Unidad de medida</p>
                                        <el-input v-model="createUnitName" size="small" placeholder="unidad" />
                                        <vee-error :text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                                <div class="col-10 text-left">
                                    <ValidationProvider v-slot="{ errors }" name="sigla" rules="required">
                                        <p class="input-label-top mt-4">Sigla</p>
                                        <el-input v-model="createUnitInitials" size="small" placeholder="siglas" />
                                        <vee-error :text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                        <div class="text-right row border-top pt-2 justify-content-end pr-3">
                            <button class="btn btn-cerrar f-12" @click="createUnitVisible = false">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="crear(createUnitName, createUnitInitials)">Guardar</button>
                        </div>
                        <el-tooltip slot="reference" content="Crear Unidad de Medida" effect="light" visible-arrow>
                            <i class="icon-plus-circle f-30 cr-pointer" @click="limpiar"/>
                        </el-tooltip>
                    </el-popover>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <draggable tag="div" :list="listMeasureUnit" class="row mx-0 py-3" handle=".handle" @change="updatePosUnidadMedida" >
                    <div v-for="lista in listMeasureUnit" :key="lista.id" class="col-12 border-nth br-4 py-1 text-muted2 pl-2">
                        <div class="row justify-content-lg-between">
                            <div class="col-lg-2 my-auto">
                                <div class="d-flex ml-3">
                                    <i class="icon-menu handle cr-pointer mr-2" />
                                    <p class="f-12 my-auto">{{ lista.unidad_medida }} ({{ lista.sigla }})</p>
                                </div>
                            </div>
                            <div class="wh-30">
                                <el-tooltip content="Materiales que usan esta unidad" effect="light" placement="right" visible-arrow>
                                    <p class="btn-general br-5 w-100 h-100 f-12 d-middle-center">{{ lista.materiales }}</p>
                                </el-tooltip>
                            </div>
                            <div class="col-lg-9 icon-option">
                                <div class="d-flex justify-content-lg-end">
                                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="lista.edit" >
                                        <div class="">
                                            <div class="row border-bottom">
                                                <div class="col-lg-12 text-center">
                                                    <p class="f-16 f-600 text-general mb-3">Editar unidad de medida</p>   
                                                </div>
                                            </div>
                                            <ValidationObserver :ref="`lista-${lista.id}`">
                                                <div class="py-4">
                                                    <div class="row justify-content-center">
                                                        <div class="col-lg-10 text-left">
                                                            <ValidationProvider v-slot="{ errors }" name="unidad de medida" rules="required">
                                                                <p class="input-label-top">Unidad de medida</p>
                                                                <el-input size="small" v-model="editUnitName" :placeholder="lista.unidad_medida" />
                                                                <vee-error :text="errors[0]"/>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-center mt-3">
                                                        <div class="col-lg-10 text-left">
                                                            <ValidationProvider v-slot="{ errors }" name="sigla" rules="required">
                                                                <p class="input-label-top">Sigla</p>
                                                                <el-input size="small" v-model="editUnitInitial" :placeholder="lista.sigla" />
                                                                <vee-error :text="errors[0]"/>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidationObserver>
                                            <div class="row border-top">
                                                <div class="col-lg-12">
                                                    <div class="d-flex justify-content-lg-end mt-3">
                                                        <button class="btn btn-cerrar f-12" @click="lista.edit = false">Cancelar</button>
                                                        <button class="btn btn-crear f-12 ml-2" @click="editar(lista.id, editUnitName, editUnitInitial)">Guardar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <el-tooltip slot="reference" content="Editar" effect="light" visible-arrow>
                                            <i class="icon-pencil f-22 mr-1 cr-pointer" @click="MostrarUnidadMedida(lista)"/>
                                        </el-tooltip>
                                    </el-popover>
                                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline f-22 cr-pointer" @click="deleteMeasureUnit(lista.id)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <modal-eliminar ref="modalDeleteUnit" title="Eliminar unidad de medida" mensaje="¿Estás seguro que quiere eliminar esta unidad de medida?" @delete="eliminar" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data(){
        return {
            editUnitName: '',
            editUnitInitial: '',
            createUnitName: '',
            createUnitInitials: '',
            idEliminar: '',
            createUnitVisible: false,
        }
    },
    computed: {
        ...mapGetters({
            listMeasureUnit: 'configurar/almacen/measure_units/listMeasureUnit',
        }),
    },
    created(){
        this.listar(),
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.unidad',
        ]);
    },
    methods: {
        ...mapActions({
           CREATE_MEASURE_UNIT: 'configurar/almacen/measure_units/CREATE_MEASURE_UNIT',
           LIST_MEASURE_UNIT: 'configurar/almacen/measure_units/LIST_MEASURE_UNIT',
           EDIT_MEASURE_UNIT: 'configurar/almacen/measure_units/EDIT_MEASURE_UNIT',
           DELETE_MEASURE_UNIT: 'configurar/almacen/measure_units/DELETE_MEASURE_UNIT',
           UPDATE_POSITION_MEASURE_UNIT: 'configurar/almacen/measure_units/UPDATE_POSITION_MEASURE_UNIT'
        }),
        deleteMeasureUnit(id){
            this.idEliminar = id
            this.$refs.modalDeleteUnit.toggle()
        },
        MostrarUnidadMedida(lista){
            this.editUnitName = lista.unidad_medida;
            this.editUnitInitial = lista.sigla;
        },
        async crear(unidad, sigla){
            let valid = await this.$refs.validatorCreate.validate()
            if (!valid) return 

            await this.CREATE_MEASURE_UNIT({payload: { unidad_medida: unidad, sigla: sigla} })
            this.createUnitVisible = false
        },
        async listar(){
            await this.LIST_MEASURE_UNIT()
        },
        async editar(id, nombre, sigla){
            let valid = await this.$refs[`lista-${id}`][0].validate()
            if (!valid) return 

            await this.EDIT_MEASURE_UNIT({id: id, payload:{unidad_medida: nombre, sigla: sigla}})
        },
        async eliminar(){
            await this.DELETE_MEASURE_UNIT(this.idEliminar)
        },
        async updatePosUnidadMedida(unidadMedida){
            const payload = {
                "id_configuracion_unidad_medidas": unidadMedida.moved.element.id,
                "materiales": unidadMedida.moved.element.materiales,
                "posicion": unidadMedida.moved.newIndex,
                "sigla": unidadMedida.moved.element.sigla,
                "unidad_medida": unidadMedida.moved.element.unidad_medida
            }
            await this.UPDATE_POSITION_MEASURE_UNIT(payload)
        },
        limpiar(){
            console.log('clic');
            this.createUnitName = ""
            this.createUnitInitials = ''
            this.editUnitName = ''
            this.editUnitInitial = ''
            this.$refs.validatorCreate.reset()
        }
    }
}
</script>

<style lang="scss" scoped>
.border-nth{
    border-top: 1px solid #dee2e6 !important;
    &:nth-last-child(1){
        border-bottom: 1px solid #dee2e6 !important;
    }
}
</style>
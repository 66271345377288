<template>
  <div class="incrementos-porcentuales container-fluid">
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Incrementos Porcentuales" class="icon-option">
                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="createUnitVisible" >
                        <ValidationObserver ref="validator">
                            <div class="">
                                <div class="row border-bottom">
                                    <div class="col-lg-12 text-center">
                                        <p class="f-16 f-600 text-general mb-3">Crear incremento</p>   
                                    </div>
                                </div>
                                <div class="row my-3 justify-content-center">
                                    <div class="col-lg-11 text-left">
                                        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                                            <p class="input-label-top">Nombre</p>
                                            <el-input v-model="incremento.nombre" placeholder="Nombre del incremento" size="small" />
                                            <vee-error :text="errors[0]"/>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row my-4 justify-content-center">
                                    <div class="col-lg-11 text-left">
                                        <ValidationProvider name="porcentaje" rules="required|numeric" v-slot="{ errors }">
                                            <p class="input-label-top">Porcentaje</p>
                                            <el-input v-model="incremento.porcentaje" placeholder="Porcentaje" size="small" />
                                            <vee-error :text="errors[0]"/>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row border-top mt-3">
                                    <div class="col-lg-12">
                                        <div class="d-flex justify-content-lg-end mt-3">
                                            <button class="btn btn-cerrar f-12 my-auto" @click="limpiarCrearIncremento">Cerrar</button>
                                            <button class="btn btn-crear f-12 ml-2 text-white" @click="createIncremento">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidationObserver>
                        <el-tooltip slot="reference" content="Crear Incremento" effect="light" visible-arrow>
                            <i class="icon-plus-circle  f-30 cr-pointer" @click="$refs.validator.reset()"/>
                        </el-tooltip>
                    </el-popover>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <draggable tag="div" :list="incrementos" class="row mx-0 py-3" handle=".handle" @change="updatePositionIncremento($event)">
                    <div v-for="increment in incrementos" :key="increment.id" class="col-12 border-nth br-4 py-1 text-muted2 pl-2">
                        <div class="row justify-content-lg-between">
                            <div class="col-lg-3 my-auto">
                                <div class="d-flex ml-3">
                                    <i class="icon-menu handle cr-pointer mr-2" />
                                    <p class="f-12 my-auto w-100">{{ increment.nombre }} ({{ increment.porcentaje }}%)</p>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="d-flex justify-content-lg-end icon-option">
                                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="increment.edit" >
                                        <div class="">
                                            <div class="row border-bottom">
                                                <div class="col-lg-12 text-center">
                                                    <p class="f-16 f-600 text-general mb-3">Editar incremento</p>   
                                                </div>
                                            </div>
                                            <div class="row my-3 justify-content-center">
                                                <div class="col-lg-11 text-left">
                                                    <p class="input-label-top">Nombre</p>
                                                    <el-input v-model="editIncremento.nombre" size="small"/>
                                                </div>
                                            </div>
                                            <div class="row my-4 justify-content-center">
                                                <div class="col-lg-11 text-left">
                                                    <p class="input-label-top">Porcentaje</p>
                                                    <el-input v-model="editIncremento.porcentaje" size="small"/>
                                                </div>
                                            </div>
                                            <div class="row border-top mt-3">
                                                <div class="col-lg-12">
                                                    <div class="d-flex justify-content-lg-end mt-3">
                                                        <button class="btn btn-cerrar f-12 my-auto" @click="increment.edit = false">Cerrar</button>
                                                        <button class="btn btn-crear f-12 ml-2 text-white" @click="edit(increment)">Guardar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <el-tooltip slot="reference" content="Editar" effect="light" visible-arrow>
                                            <i class="icon-pencil f-22 mr-1 cr-pointer" @click="openEditIncremento(increment)" />
                                        </el-tooltip>
                                    </el-popover>
                                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline f-22 cr-pointer" @click="openModalDelete(increment.id)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <modal ref="modalDeleteIncrement" titulo="Eliminar incremento" :btn-eliminar="true" @eliminar="deleteIncremento">
            <div class="container">
                <div class="row my-4">
                    <div class="col-lg-12 text-center">
                        <p class="f-12">¿Está seguro que quiere eliminar este incremento?</p>
                    </div>
                </div>
            </div>
        </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { incrementosFactory } from "~/domain/entities/configurar/almacen/incrementos_porcentuales/factories/incrementosFactory"
export default {
    data(){
        return {
            createUnitVisible: false,
            idToDelete: Number,
        }
    },
    created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.incrementos_porcentuales',
        ]);
        this.listar()
    },
    computed: {
        ...mapGetters({
            incrementos: 'configurar/almacen/incrementos_porcentuales/incrementos',
        }),
        incremento:{
            get(){return this.$store.getters['configurar/almacen/incrementos_porcentuales/incremento']},
            set(val){this.$store.commit('configurar/almacen/incrementos_porcentuales/set_incremento', val)}
        },
        editIncremento:{
            get(){return this.$store.getters['configurar/almacen/incrementos_porcentuales/editIncremento']},
            set(val){this.$store.commit('configurar/almacen/incrementos_porcentuales/set_edit_incremento', val)}
        },
    },
    methods: {
        ...mapActions({
            ACTION_CREATE_INCREMENTOS: 'configurar/almacen/incrementos_porcentuales/ACTION_CREATE_INCREMENTOS',
            ACTION_GET_INCREMENTOS: 'configurar/almacen/incrementos_porcentuales/ACTION_GET_INCREMENTOS',
            ACTION_UPDATE_INCREMENTOS: 'configurar/almacen/incrementos_porcentuales/ACTION_UPDATE_INCREMENTOS',
            ACTION_DELETE_INCREMENTOS: 'configurar/almacen/incrementos_porcentuales/ACTION_DELETE_INCREMENTOS',
            ACTION_UPDATE_POSITION_INCREMENTOS: 'configurar/almacen/incrementos_porcentuales/ACTION_UPDATE_POSITION_INCREMENTOS'
        }),
        openEditIncremento(incremento){
            this.editIncremento.id = incremento.id
            this.editIncremento.nombre = incremento.nombre
            this.editIncremento.porcentaje = incremento.porcentaje
        },
        openModalDelete(id){
            this.idToDelete = id
            this.$refs.modalDeleteIncrement.toggle()
        },
        limpiarCrearIncremento(){
            this.createUnitVisible = false
            this.incremento = incrementosFactory.instantiateEmpty()
        },

        //CRUD INCREMENTO
        async createIncremento(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            await this.ACTION_CREATE_INCREMENTOS()
            this.limpiarCrearIncremento()
        },
        async listar(){
            await this.ACTION_GET_INCREMENTOS()
        },
        async edit(increment){
            await this.ACTION_UPDATE_INCREMENTOS()
            increment.edit = false
        },
        async deleteIncremento(){
            this.ACTION_DELETE_INCREMENTOS(this.idToDelete)
            this.$refs.modalDeleteIncrement.toggle()
        },
        async updatePositionIncremento(mat){
            const payload = {
                "id_configuracion_incrementos_porcentuales": mat.moved.element.id,
                "posicion": mat.moved.newIndex 
            }
            await this.ACTION_UPDATE_POSITION_INCREMENTOS(payload)
            this.listar()
        }
    }
}
</script>


<style lang="scss" scoped>
.border-nth{
    border-top: 1px solid #dee2e6 !important;
    &:nth-last-child(1){
        border-bottom: 1px solid #dee2e6 !important;
    }
}
</style>

<template>
  <div class="container-fluid">
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Datos adicionales" class="icon-option">
                    <el-popover placement="bottom-end" width="350" trigger="click" v-model="createAdditionalData" >
                        <div class="row border-bottom d-middle-center">
                            <p class="f-16 f-600 text-general mb-3">Crear dato</p>
                        </div>
                        <ValidationObserver ref="validator">
                            <div class="row mx-0 d-middle-center py-4">
                                <div class="col-10 text-left">
                                    <ValidationProvider v-slot="{ errors }" name="pregunta" rules="required">
                                        <p class="input-label-top">Pregunta</p>
                                        <el-input v-model="additionalData.pregunta" size="small" placeholder="Pregunta" />
                                        <vee-error :text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                                <div class="col-10 text-left">
                                    <ValidationProvider v-slot="{ errors }" name="tipo" rules="required">
                                        <p class="input-label-top mt-4">Tipo</p>
                                        <el-select v-model="additionalData.tipo" size="small" placeholder="Seleccionar tipo" class="w-100">
                                            <el-option
                                                v-for="type in Object.values(DATA_TYPE_INFO)"
                                                :key="type.id"
                                                :label="type.name"
                                                :value="type.id"
                                            />
                                        </el-select>
                                        <vee-error :text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                        <div class="text-right row border-top pt-2 justify-content-end pr-3">
                            <button class="btn btn-cerrar f-12" @click="cleanCrearDato()">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="handleCreate">Guardar</button>
                        </div>
                        <el-tooltip slot="reference" content="Crear dato" effect="light" visible-arrow>
                            <i @click="$refs.validator.reset()" class="icon-plus-circle f-30 cr-pointer" />
                        </el-tooltip>
                    </el-popover>
                </titulo-divisor>
            </div>
        </div>
        <div class="container-fluid">
            <draggable tag="div" :list="datos_adicionales" class=" border-top" handle=".handle" @change="dragMouseDown($event)">
                <div v-for="add in datos_adicionales" :key="add.id" class="border-bottom br-4 text-muted2 pl-2">
                    <div class="row mx-0 my-2 justify-content-between align-items-center">
                        <div class="col-lg-4 d-flex ml-3">
                            <i class="icon-menu handle cr-pointer mr-2" />
                            <p class="f-12 my-auto">{{ add.pregunta }}</p>
                        </div>
                        <div class="col-lg-4 d-flex">
                            <div class="col-auto border br-5 px-0" style="width: 225px;">
                                <div class="d-flex br-5 align-items-center">
                                    <i class="br-5 bg-db" :class="getTypeIcon(add.tipo)" />
                                    <p class="text-muted f-14 f-600 ml-2">{{DATA_TYPE_INFO[add.tipo] && DATA_TYPE_INFO[add.tipo].name || DATA_TYPE_INFO_DEFAULT.name}}</p>
                                </div>
                            </div>
                            
                            <el-tooltip content="Editar" effect="light" visible-arrow>
                                <i class="icon-pencil-outline icon-general-hover f-20 my-auto cr-pointer" v-if="VALUE_LIST_CODE.includes(add.tipo)" @click="editAdditionalData(add)" />
                            </el-tooltip>

                        </div>
                        <div class="d-flex mr-4">
                            <el-popover
                                placement="bottom-end"
                                width="350"
                                trigger="click"
                                v-model="add.edit"
                                >
                                <div class="container">
                                    <div class="border-bottom text-center">
                                        <p class="f-16 f-600 text-general mb-3">Editar dato</p>   
                                    </div>
                                    <p class="text-left mt-4 input-label-top">Pregunta</p>
                                            <el-input v-model="inputEditDato" size="small" />
                                            <p v-if="!validEdit" class="f-11 ml-2 text-red">El campo pregunta es obligatorio</p>
                                        <p class="text-left input-label-top mt-4">Tipo</p>
                                            <el-select v-model="add.tipo" placeholder="Seleccionar tipo" size="small" class="w-100">
                                                <el-option
                                                    v-for="type in Object.values(DATA_TYPE_INFO)"
                                                    :key="type.id"
                                                    :label="type.name"
                                                    :value="type.id"
                                                />
                                            </el-select>
                                    <div class="d-flex border-top justify-content-lg-end mt-4 pt-3">
                                        <button class="btn btn-cerrar f-12" @click="add.edit = false">Cerrar</button>
                                        <button class="btn btn-crear f-12 ml-2" @click="updateAdditionalData(add)">Guardar</button>
                                    </div>
                                </div>
                                <i slot="reference" @click="editInput(add.pregunta)" class="icon-pencil icon-general-hover f-20 mr-3 cr-pointer" />
                            </el-popover>
                            <i class="icon-trash-can-outline icon-general-hover f-20 cr-pointer" @click="deleteAdditionalData(add)" />
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <modalEliminarAD ref="modalDeleteAdditionalData" @eliminar="handleDelete" />
        <modalEditarAD ref="modalEditAdditionalData" />
  </div>
</template>

<script>
import modalEliminarAD from './partials/modalEliminarAD.vue'
import modalEditarAD from './partials/modalEditarAD.vue'
import { mapGetters, mapActions } from 'vuex'
import { AdditionalDataFactory } from '~/domain/entities/configurar/almacen/datos_adicionales/factories/AdditionalDataFactory'

export default {
    components: {
        modalEliminarAD,
        modalEditarAD,
    },
    data(){
        return {
            VALUE_LIST_CODE: [2,3],
            DATA_TYPE_INFO: {
                1: {
                    id: 1,
                    name: 'Pregunta abierta',
                    icon: 'icon-text-long br-5 text-white f-21 my-auto px-1 bg-db'
                },
                2: {
                    id: 2,
                    name: 'Selecciona un tipo',
                    icon: 'icon-down-open br-5 text-white f-21 my-auto px-1 bg-db'
                },
                3: {
                    id: 3,
                    name: 'Lista de valores',
                    icon: 'icon-format-list-numbered br-5 text-white f-21 my-auto px-1 bg-db'
                },
                4: {
                    id: 4,
                    name: 'Número',
                    icon: 'icon-numeric br-5 text-white f-21 my-auto px-1 bg-db'
                }
            },
            DATA_TYPE_INFO_DEFAULT: {
                id: 0,
                name: 'Sin tipo',
                icon: 'icon-information-outline br-5 text-white f-21 my-auto px-1 bg-db'
            },
            toDelete: null,
            additionalData: AdditionalDataFactory.instantiateEmpty(),
            createAdditionalData: false,
            inputEditDato: "",
            validEdit: true
        }
    },
    watch: {
        inputEditDato(val){
            this.validEdit = val ? true : false
        }
    },
    created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.datos_adicionales',
        ]);
    },
    async mounted(){
        await this.obtenerDatosAdicionales()
    },
    computed:{
        ...mapGetters({
            datos_adicionales: 'configurar/almacen/datos_adicionales/datos_adicionales',
            datos_adicionales_posicion: 'configurar/almacen/datos_adicionales/datos_adicionales_posicion',
        }),
    },
    methods: {
        ...mapActions({
            obtenerDatosAdicionales: 'configurar/almacen/datos_adicionales/Action_get_datos_adicionales',
            Action_get_datos_adicionales_posicion: 'configurar/almacen/datos_adicionales/Action_get_datos_adicionales_posicion',
            obtenerTipoDatosAdicionales: 'configurar/almacen/datos_adicionales/Action_get_datos_adicionales_tipos_unico',
            crearDatoAdicional: 'configurar/almacen/datos_adicionales/Action_create_datos_adicionales',
            eliminarDatoAdicional: 'configurar/almacen/datos_adicionales/Action_delete_datos_adicionales',
            editarDatoAdicional: 'configurar/almacen/datos_adicionales/Action_edit_datos_adicionales',
        }),
        editAdditionalData(additionalData){
            this.$refs.modalEditAdditionalData.toggle(additionalData)
        },
        deleteAdditionalData(dataToDelete){
            this.toDelete = dataToDelete.id
            this.$refs.modalDeleteAdditionalData.toggle()
        },
        getTypeIcon(dataType){
            return this.DATA_TYPE_INFO?.[dataType]?.icon ?? this.DATA_TYPE_INFO_DEFAULT.icon
        },
        async handleCreate(){
            let valid = await this.$refs.validator.validate()
            if (!valid) return 

            await this.crearDatoAdicional(this.additionalData)
            this.cleanCrearDato()
        },
        async handleDelete(){
            await this.eliminarDatoAdicional({id: this.toDelete})
        },
        async updateAdditionalData(additionalData){
            if(!this.validEdit) return;
            
            additionalData.pregunta = this.inputEditDato
            await this.editarDatoAdicional({id: additionalData.id, payload: additionalData})
        },
        async dragMouseDown(e){
            let payload = {
                "id_configuracion_datos_adicionales": e.moved.element.id,
                "posicion": e.moved.newIndex + 1,
            }
            await this.Action_get_datos_adicionales_posicion(payload)
            await this.obtenerDatosAdicionales()
        },
        editInput(nombre){
            this.inputEditDato=nombre
        },
        cleanCrearDato(){
            this.createAdditionalData = false
            this.additionalData.pregunta = ''
            this.additionalData.tipo = ''
        }
    }
}
</script>

<style lang="scss" scoped>
</style>